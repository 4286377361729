<div
  class="flex flex-col gap-5 px-6 py-5 border border-gray-200 rounded-lg bg-white h-full"
>
  <div class="flex justify-between">
    <span class="text-lg font-semibold text-gray-900">{{
      orderPrefix + "delivery-information" | translate | sentenceCase
    }}</span>
    <div>
      <button
        *ngIf="isReshipMode"
        class="font-semibold text-blue-600 h-9 w-fit"
        (click)="onEditDeliveryInformation()"
      >
        {{ "section-action.edit" | translate | sentenceCase }}
      </button>
    </div>
  </div>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-4">
      <div class="flex flex-row gap-4">
        <div class="w-1/3">
          <div class="flex flex-col gap-1">
            <span class="text-sm font-medium text-gray-500">{{
              "common.name" | translate | sentenceCase
            }}</span>
            <span class="text-sm font-medium text-gray-700">{{
              order?.address?.user_full_name || "--"
            }}</span>
          </div>
        </div>

        <div class="w-1/3">
          <div class="flex flex-col gap-1">
            <span class="text-sm font-medium text-gray-500">{{
              "common.phone" | translate | sentenceCase
            }}</span>
            <div class="flex flex-row gap-[2px] items-center">
              <span class="text-sm font-medium text-gray-700">
                <span *ngIf="order?.address">
                  {{
                    "(" +
                      order?.address?.user_phone_number?.substring(0, 3) +
                      ")"
                  }}
                </span>

                {{
                  order?.address?.user_phone_number?.substring(3) ?? "--"
                }}</span
              >
              <a
                *ngIf="order?.address?.user_phone_number"
                class="flex items-center text-primary-700 min-w-max"
                href="tel:{{ order?.address?.user_phone_number }}"
              >
                <span class="inline">
                  <i class="{{ SoctripIcons.PHONE }} text-blue-500 text-xl"></i>
                </span>
                <span class="hidden md:hidden">
                  {{ textDescription + "call-now" | translate | sentenceCase }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <span class="text-sm text-gray-500 font-medium">{{
          "common.address" | translate | sentenceCase
        }}</span>
        <span class="text-sm text-gray-700 font-medium line-clamp-1">{{
          getAddress(order) || "--"
        }}</span>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Update delivery information"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '608px' }"
>
  <form [formGroup]="deliveryInfoForm">
    <div class="flex flex-col gap-3">
      <!-- FullName & PhoneNumber -->
      <div class="flex gap-4">
        <div>
          <span class="text-sm font-medium text-gray-700">{{
            orderPrefix + "fullname" | translate | sentenceCase
          }}</span>
          <input
            type="text"
            pInputText
            class="w-[272px]"
            formControlName="fullName"
          />
        </div>
        <div class="w-full">
          <span class="text-sm font-medium text-gray-700">{{
            orderPrefix + "phone-number" | translate | sentenceCase
          }}</span>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon !px-0">
              <p-dropdown
                optionLabel="code"
                class="hidden-border"
                [options]="countryCode"
              ></p-dropdown>
            </span>
            <input
              type="text"
              pInputText
              class="w-[272px]"
              formControlName="phoneNumber"
            />
          </div>
        </div>
      </div>

      <!-- Adress line 1 -->
      <div>
        <div>
          <span class="text-sm font-medium text-gray-700">{{
            orderPrefix + "address-line-1" | translate | sentenceCase
          }}</span>
          <input
            type="text"
            pInputText
            class="w-full"
            formControlName="addressLine1"
          />
        </div>
      </div>

      <!-- Adress line 2 -->
      <div>
        <div>
          <span class="text-sm font-medium text-gray-700"
            >{{
              orderPrefix + "address-line-2" | translate | sentenceCase
            }}
            &nbsp;({{
              orderPrefix + "optional" | translate | sentenceCase
            }})</span
          >
          <input
            type="text"
            pInputText
            class="w-full"
            formControlName="addressLine2"
          />
        </div>
      </div>

      <!-- City && State && Provice Country -->

      <div class="grid grid-cols-2 gap-4">
        <!-- Country -->
        <div class="flex flex-col">
          <span class="text-sm font-medium text-gray-700">{{
            orderPrefix + "country" | translate | sentenceCase
          }}</span>
          <p-dropdown
            appendTo="body"
            [options]="countries"
            optionLabel="country_name"
            optionValue="country_id"
            formControlName="country"
            [placeholder]="
              orderPrefix + 'select-a-country' | translate | sentenceCase
            "
            styleClass="w-full"
            (onChange)="onUpdateCountry($event)"
          />
        </div>

        <!-- State && Provice -->
        <div class="flex flex-col">
          <span class="text-sm font-medium text-gray-700">{{
            orderPrefix + "city" | translate | sentenceCase
          }}</span>
          <p-dropdown
            appendTo="body"
            optionLabel="province_name"
            optionValue="province_id"
            [options]="provinces"
            [placeholder]="
              orderPrefix + 'select-a-city' | translate | sentenceCase
            "
            styleClass="w-full"
            (onChange)="onUpdateProvince($event)"
            formControlName="province"
          />
        </div>

        <!-- City -->
        <div class="flex flex-col">
          <span class="text-sm font-medium text-gray-700">{{
            orderPrefix + "district" | translate | sentenceCase
          }}</span>
          <p-dropdown
            appendTo="body"
            optionLabel="district_name"
            optionValue="district_id"
            [options]="districts"
            [placeholder]="
              orderPrefix + 'select-a-district' | translate | sentenceCase
            "
            styleClass="w-full"
            (onChange)="onUpdateDistrict($event)"
            formControlName="district"
          />
        </div>

        <!-- Ward -->
        <div class="flex flex-col">
          <span class="text-sm font-medium text-gray-700">{{
            orderPrefix + "ward" | translate | sentenceCase
          }}</span>
          <p-dropdown
            appendTo="body"
            optionLabel="ward_name"
            optionValue="ward_id"
            [options]="wards"
            [placeholder]="
              orderPrefix + 'select-a-ward' | translate | sentenceCase
            "
            styleClass="w-full"
            formControlName="ward"
          />
        </div>
      </div>
    </div>
  </form>
  <!-- Footer -->
  <ng-template pTemplate="footer" class="flex gap-3">
    <app-button
      class="!h-11 !bg-transparent !text-gray-900 !border-gray-300"
      (onClick)="visible = false"
      [label]="actionPrefix + 'cancel' | translate | titlecase"
    ></app-button>

    <app-button
      class="!h-11"
      [loading]="isLoading"
      (onClick)="onHandleSubmit(order.id ?? '')"
      styleClass="border-orange-dark-600 text-orange-dark-600"
      [label]="actionPrefix + 'save' | translate | titlecase"
    ></app-button>
  </ng-template>
</p-dialog>
