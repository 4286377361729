<div
  (click)="op.toggle($event)"
  class="relative flex-1 border border-gray-300 rounded-md w-[360px] h-11 flex items-center px-4 cursor-pointer hover:border-blue-500 transition"
>
  <span *ngIf="optionSelected" class="text-sm font-semibold mr-1">
    {{ "section-time." + optionSelected | translate }}:
  </span>
  <span *ngIf="dateTitle" class="text-sm"> {{ dateTitle }}</span>
  <i
    class="absolute right-4 top-1/2 translate-y-[-50%] cursor-pointer"
    [class]="SoctripIcons.CALENDAR"
  >
  </i>
</div>
<p-overlayPanel #op>
  <div class="min-w-[500px] flex gap-2 !-m-5">
    <div class="py-4 pr-2 border-r">
      <ul class="flex flex-col gap-1 px-2">
        <li
          *ngFor="let option of optionsChoose"
          class="font-medium text-sm cursor-pointer hover:bg-gray-100 py-1 px-4 rounded"
          [ngClass]="{
            'text-primary-600': option.value === optionSelected
          }"
          (click)="onSelectOption(option)"
        >
          {{ "section-time." + option.value | translate }}
        </li>
      </ul>
      <ul class="flex flex-col gap-1 px-2 mt-3 pt-3 border-t">
        <li
          *ngFor="let option of optionsSelect"
          class="font-medium text-sm cursor-pointer hover:bg-gray-100 flex items-center justify-between rounded py-1 px-4"
          [ngClass]="{
            'text-primary-600 bg-blue-50': option.value === optionSelected
          }"
          (click)="onSelectOption(option)"
        >
          <span>{{ "section-time." + option.value | translate }}</span>
          <i class="pi pi-angle-right"></i>
        </li>
        <li
          *ngIf="isHadAllTime"
          class="font-medium text-sm cursor-pointer hover:bg-gray-100 flex items-center justify-between rounded py-1 px-4"
          [ngClass]="{
            'text-primary-600 bg-blue-50': 'all-time' === optionSelected
          }"
          (click)="onSelectAllTime()"
        >
          <span>{{ "section-time.all-time" | translate }}</span>
        </li>
      </ul>
    </div>
    <div class="flex-1">
      <p-calendar
        styleClass="w-full"
        panelStyleClass="!border-0"
        dateFormat="dd/mm/yy"
        [ngModel]="date"
        [inline]="true"
        [selectionMode]="mode"
        [view]="view"
        [disabled]="disabled"
        [readonlyInput]="true"
        (onSelect)="onSelectDate($event)"
      ></p-calendar>
    </div>
  </div>
</p-overlayPanel>
