export enum SummaryIdEnum {
  SALES = 'sales',
  TOTAL_ORDERS = 'total_orders',
  CANCELLED_ORDERS = 'cancelled_orders',
  CONVERSION_RATE = 'conversion_rate',
  VISITORS = 'visitors',
  SHOP_VIEWS = 'shop_views',
  SALE_PER_ORDER = 'sale_per_order',
  REFUND_ORDERS = 'refund_orders',
  REFUND_SALES = 'refund_sales',
  BUYER = 'buyer',
  SALE_PER_BUYER = 'sale_per_buyer',
}

export enum ComponentUnitEnum {
  PRICE = 'price',
  PERCENT = 'percent',
  NORMAL = 'normal',
}

export enum RankingTabEnum {
  SALE = 'SALE',
  SOLD = 'SOLD',
  VIEW = 'VIEW',
  CONVERSION_RATE = 'CONVERSION_RATE',
}

export enum StatisticEnum {
  REVENUES_GMV = 'REVENUES_GMV',
  SELLER_SELLING = 'SELLER_SELLING',
  TOTAL_ORDES = 'TOTAL_ORDES',
  PAGE_VIEW = 'PAGE_VIEW',
  USER_VIEW = 'USER_VIEW',
  EVERAGE_VIEWS_PER_USER = 'EVERAGE_VIEWS_PER_USER',
  CONVERSION_RATE = 'CONVERSION_RATE',
  USER_VIEW_CONVERSION_RATE = 'USER_VIEW_CONVERSION_RATE',
  GMV_PER_STORE = 'GMV_PER_STORE',
  EVERAGE_ORDER_VALUE_AOV = 'EVERAGE_ORDER_VALUE_AOV',
  CANCELLATION_RATE = 'CANCEL_REFUND_SALES',
  REFUNDED_ORDERS = 'REFUND_ORDERS',
}
