import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import * as moment from 'moment';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';

@Component({
  selector: 'app-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTimeComponent),
      multi: true,
    },
  ],
})
export class SelectTimeComponent implements OnInit, ControlValueAccessor {
  readonly SoctripIcons = SoctripIcons;
  @ViewChild('secondInput') secondInput: ElementRef;
  @ViewChild('minuteInput') minuteInput: ElementRef;
  @Input() minDate: Date;
  @Input() styleClass: string;
  formControl: FormControl;
  visible=false
  dateString = '';
  date?: Date;
  hours = '00';
  minutes = '00';
  seconds = '00';
  disabled: boolean;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector
        .get(FormGroupDirective)
        .getControl(ngControl);
    }
    this.date = this.formControl.value;
    this.disabled = !this.formControl.enabled;
    if (this.date) {
      this.dateString = moment(this.date).format('MM/DD/yyyy HH:mm:ss');
      this.hours = String(0).padStart(2, '0');
      this.minutes = String(0).padStart(2, '0');
      this.seconds = String(0).padStart(2, '0');
    }

    this.formControl.valueChanges.subscribe((value) => {
      if (!value) {
        this.date = undefined;
        this.dateString = '';
        this.hours = '00';
        this.minutes = '00';
        this.seconds = '00';
      }
    });

    this.formControl.statusChanges.subscribe((status) => {
      if (status === 'DISABLED') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    });
  }

  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onChangeTime(time: 'hours' | 'minutes' | 'seconds') {
    if (Number(this.hours) > 23) {
      this.hours = '23';
    }
    if (Number(this.minutes) > 59) {
      this.minutes = '59';
    }
    if (Number(this.seconds) > 59) {
      this.seconds = '59';
    }
    if (Number(this.hours) < 0) {
      this.hours = '00';
    }
    if (Number(this.minutes) < 0) {
      this.hours = '00';
    }
    if (Number(this.seconds) < 0) {
      this.seconds = '00';
    }

    if (this.hours.length == 2 && time == 'hours') {
      this.minuteInput.nativeElement.focus();
    }

    if (this.minutes.length == 2 && time == 'minutes') {
      this.secondInput.nativeElement.focus();
    }

    if (!this.date) {
      if (this.minDate) {
        this.date = new Date(moment(this.minDate).add('days', 1).toDate());
      } else {
        this.date = new Date();
      }
    }
    this.onSetDate();
  }

  onChangeDate() {
    this.onSetDate();
  }

  onSetDate() {
    if (this.date) {
      const date = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate(),
        Number(this.hours),
        Number(this.minutes),
        Number(this.seconds),
      );

      this.dateString = moment(date).format('MM/DD/yyyy HH:mm:ss');
      this.date = new Date(date);
      this.formControl.setValue(this.date);
    }
  }

  writeValue(obj: any): void {
    this.onChange(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onChange(value: string) {}

  onTouch() {}
}
