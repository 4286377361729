import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileControllerService } from '@soctrip/angular-storage-service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { StatusEnum } from 'src/app/core/enum/status.enum';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-custom-upload-cropper',
  templateUrl: './custom-upload-cropper.component.html',
  styleUrls: ['./custom-upload-cropper.component.scss'],
})
export class CustomUploadCropperComponent {
  @Input() imageId: string | null = '';
  @Input() styleClass: string = '';
  @Input() isEditMode: boolean = false;
  @Input() styleClassRequired: string;
  @Output() changeImageData = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() statusVoucher: string = '';

  readonly SoctripIcons = SoctripIcons;
  StatusEnum = StatusEnum;
  promotionProgram = 'promotion-program.';
  croppedImage: any = '';
  croppedFile: any = '';
  uploadImage: any = '';
  imageChangedEvent: any = '';
  imageName: string = '';
  imageSize: number = 0;
  isLoading: boolean = false;
  isCropperModal = false;

  constructor(
    private sanitizer: DomSanitizer,
    private storageService: FileControllerService,
    private fileService: FileService,
  ) {}

  ngOnInit() {
    if (this.imageId) {
      this.storageService.getFile(this.imageId).subscribe({
        next: (res) => {
          this.imageName = res.data?.original_name;
          this.imageSize = res.data?.size;
        },
        error: (e) => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }

  onInputChange(event: any) {
    this.imageChangedEvent = event;
    const file = event?.target?.files[0];
    this.onFileSelected(file);
  }

  onFileSelected(file: any): void {
    if (file && file.type.startsWith('image/')) {
      this.imageName = file?.name || '';
      this.imageSize = file?.size || 0;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.croppedImage = e.target.result;
        this.uploadImage = e.target.result;
        this.isLoading = false;
        this.changeImageData.emit({ id: this.uploadImage, file: file });
      };
      reader.readAsDataURL(file);
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    this.imageChangedEvent = { target: { files: event.dataTransfer?.files } };
    if (file) {
      this.onFileSelected(file);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl as string,
    );
    this.croppedFile = event.blob;
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob as any], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }

  replaceImage(): void {
    this.fileInput.nativeElement.click();
  }

  removeImage(): void {
    this.resetUpload();
  }

  resetUpload(): void {
    this.croppedImage = '';
    this.uploadImage = '';
    this.imageName = '';
    this.imageSize = 0;
    this.fileInput.nativeElement.value = '';
    this.isLoading = false;
    this.imageChangedEvent = '';
    this.imageId = '';
    this.changeImageData.emit('');
  }

  getAvatarDefault() {
    return 'assets/imgs/default/avatar.webp';
  }

  getImageThumbnail() {
    return this.fileService.getThumbnailWebp(this.imageId);
  }

  onConfirmCropper() {
    this.uploadImage = this.croppedImage;
    if (this.croppedFile) {
      const file = this.blobToFile(this.croppedFile, this.imageName);
      this.changeImageData.emit({
        id: this.uploadImage,
        file: file,
      });
    }

    this.isCropperModal = false;
  }
}
