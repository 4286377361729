import {
  ComponentUnitEnum,
  RankingTabEnum,
  StatisticEnum,
} from '../enum/dashboard.enum';

export interface ICalendarOption {
  value: string;
  title: string;
}

export const CalendarOptionsChoose: ICalendarOption[] = [
  {
    value: 'today',
    title: 'section-time.today',
  },
  {
    value: 'yesterday',
    title: 'section-time.yesterday',
  },
  {
    value: 'pastWeek',
    title: 'section-time.past-7-days',
  },
  {
    value: 'pastMonth',
    title: 'section-time.past-30-days',
  },
];

export const CalendarOptionsSelect: ICalendarOption[] = [
  {
    title: 'section-time.by-day',
    value: 'byDay',
  },
  {
    title: 'section-time.by-week',
    value: 'byWeek',
  },
  {
    title: 'section-time.by-month',
    value: 'byMonth',
  },
  {
    title: 'section-time.by-year',
    value: 'byYear',
  },
];

export const ProductRankingTabConfig = [
  {
    title: 'by-revenue',
    value: RankingTabEnum.SALE,
  },
  {
    title: 'by-units-sold',
    value: RankingTabEnum.SOLD,
  },
  {
    title: 'by-views',
    value: RankingTabEnum.VIEW,
  },
  {
    title: 'by-conversion-rate',
    value: RankingTabEnum.CONVERSION_RATE,
  },
];

export const ShopsRankingTabConfig = [
  {
    title: 'by-revenue',
    value: RankingTabEnum.SALE,
  },
  {
    title: 'by-units-sold',
    value: RankingTabEnum.SOLD,
  },
  {
    title: 'by-views',
    value: RankingTabEnum.VIEW,
  },
  // {
  //   title: 'by-conversion-rate',
  //   value: RankingTabEnum.CONVERSION_RATE,
  // },
];

export const CategoryRankingTabConfig = [
  {
    title: 'by-revenue',
    value: RankingTabEnum.SALE,
  },
  {
    title: 'by-units-sold',
    value: RankingTabEnum.SOLD,
  },
];

export const StatisticsConfig = [
  // SECTION 1
  {
    id: StatisticEnum.REVENUES_GMV,
    amount: 0,
    type: ComponentUnitEnum.PRICE,
    title: 'revenue-gmv',
    tooltip: 'revenue-gmv',
  },

  {
    id: StatisticEnum.SELLER_SELLING,
    amount: 0,
    type: ComponentUnitEnum.NORMAL,
    title: 'seller-selling',
    tooltip: 'seller-selling',
  },

  {
    id: StatisticEnum.TOTAL_ORDES,
    amount: 0,
    type: ComponentUnitEnum.NORMAL,
    title: 'total-orders',
    tooltip: 'total-orders',
  },

  // SECTION 2
  {
    id: StatisticEnum.PAGE_VIEW,
    amount: 0,
    type: ComponentUnitEnum.NORMAL,
    title: 'page-view',
    tooltip: 'page-view',     
  },

  {
    id: StatisticEnum.USER_VIEW,
    amount: 0,
    type: ComponentUnitEnum.NORMAL,
    title: 'user-view', 
    tooltip: 'user-view',
  },
 
  {
    id: StatisticEnum.EVERAGE_VIEWS_PER_USER,
    amount: 0,
    type: ComponentUnitEnum.NORMAL,
    title: 'average-views-per-user',
    unit: 'common.user',
    tooltip: 'average-views-per-user', 
  },

  // SECTION 3
  {
    id: StatisticEnum.CONVERSION_RATE,
    amount: 0,
    type: ComponentUnitEnum.PERCENT,
    title: 'conversion-rate-pv-cr',
    tooltip: 'conversion-rate-pv-cr',
  },

  {
    id: StatisticEnum.USER_VIEW_CONVERSION_RATE,
    amount: 0,
    type: ComponentUnitEnum.PERCENT,
    title: 'user-view-conversion-rate-uv-cr',
    tooltip: 'user-view-conversion-rate-uv-cr',
  },

  {
    id: StatisticEnum.GMV_PER_STORE,
    amount: 0,
    type: ComponentUnitEnum.PRICE,
    title: 'gmv-per-store',
    unit: 'common.store',
    tooltip: 'gmv-per-store',
  },
  {
    id: StatisticEnum.EVERAGE_ORDER_VALUE_AOV,
    amount: 0,
    type: ComponentUnitEnum.PRICE,
    title: 'average-order-value-aov',
    tooltip: 'average-order-value-aov',
  },
  {
    id: StatisticEnum.CANCELLATION_RATE,
    amount: 0,
    type: ComponentUnitEnum.PERCENT,
    title: 'cancellation-rate',
    tooltip: 'cancellation-rate',
  },
  {
    id: StatisticEnum.REFUNDED_ORDERS,
    amount: 0,
    type: ComponentUnitEnum.NORMAL,
    title: 'refunded-orders',
    tooltip: 'refunded-orders',
  },
];
