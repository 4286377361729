<ng-container
  *ngIf="croppedImage || imageId; then uploaded; else upload"
></ng-container>

<!-- UPLOAD -->
<ng-template #upload>
  <div
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    *ngIf="!isEditMode || statusVoucher !== StatusEnum.ONGOING"
    class="upload-container border border-gray-300 shadow-sm rounded-lg p-4 flex flex-col items-center gap-3 w-full cursor-pointer"
    [class.shadow]="!croppedImage"
    (click)="replaceImage()"
    [ngClass]="styleClassRequired"
  >
    <div
      class="flex p-2 justify-center items-center rounded-[28px] border border-gray-50 bg-gray-100"
    >
      <span class="{{ SoctripIcons.UPLOAD_CLOUD_02 }} text-base"></span>
    </div>

    <div class="flex flex-col gap-1 items-center">
      <span *ngIf="!croppedImage" class="text-sm font-medium text-gray-700">
        <span class="text-blue-500 mr-1">{{
          promotionProgram + "promotion-program-shop-list.click-to-upload"
            | translate
            | sentenceCase
        }}</span>
        {{
          promotionProgram + "promotion-program-shop-list.or-drag-and-drop"
            | translate
        }}
      </span>
      <span class="text-xs text text-gray-500">{{
        promotionProgram + "promotion-program-shop-list.accepts-png-or-jpg"
          | translate
          | sentenceCase
      }}</span>
    </div>
    <input
      #fileInput
      type="file"
      accept="image/*"
      (change)="onInputChange($event)"
      hidden
    />
  </div>
</ng-template>

<!-- UPLOADED -->
<ng-template #uploaded>
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <div
      class="flex flex-1 items-center flex-row gap-6"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
    >
      <div class="relative">
        <img
          *ngIf="uploadImage || (imageId && getImageThumbnail())"
          [src]="uploadImage || getImageThumbnail()"
          alt="uploadedImage"
          class="w-[150px] h-[150px] object-cover rounded-lg border border-gray-200 shadow-sm"
        />
        <div
          *ngIf="croppedImage"
          class="absolute bg-black/40 hover:bg-black/80 w-7 h-7 rounded-full cursor-pointer flex items-center justify-center top-2 right-2"
          (click)="isCropperModal = true"
        >
          <i class="sctr-icon-edit-01 text-white text-sm"></i>
        </div>
      </div>

      <div
        *ngIf="!isEditMode && (croppedImage || imageId)"
        class="image-details flex flex-col gap-6"
      >
        <div class="flex flex-col gap-1">
          <span class="text-sm font-medium text-gray-700">{{
            imageName ?? "--"
          }}</span>
          <span class="text-sm text-gray-500">{{ imageSize ?? 0 }} KB</span>
        </div>
        <div class="flex flex-row gap-2">
          <button
            *ngIf="statusVoucher !== StatusEnum.ONGOING"
            (click)="replaceImage()"
            class="btn btn-sm btn-secondary-gray"
          >
            {{
              promotionProgram + "promotion-program-shop-list.replace"
                | translate
                | sentenceCase
            }}
          </button>
          <button
            *ngIf="statusVoucher !== StatusEnum.ONGOING"
            (click)="removeImage()"
            class="btn btn-sm btn-secondary-gray"
          >
            {{
              promotionProgram + "promotion-program-shop-list.remove"
                | translate
                | sentenceCase
            }}
          </button>
        </div>
      </div>

      <input
        #fileInput
        type="file"
        accept="image/svg+xml, image/png, image/jpeg"
        (change)="onInputChange($event)"
        hidden
      />
    </div>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div *ngIf="isLoading" class="grid grid-cols-1 gap-3">
    <div class="p-7">
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '10px' }"
      ></p-progressBar>
    </div>
    <div class="grid grid-cols-1 items-center gap-6 p-7">
      <div class="grid grid-cols-2 justify-start gap-2 w-max">
        <p-skeleton class="w-[100px]"></p-skeleton>
        <p-skeleton class="w-[100px]"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<p-dialog
  [modal]="true"
  [draggable]="false"
  header="Update thumbnail"
  [(visible)]="isCropperModal"
>
  <image-cropper
    style="width: 500px; height: 500px"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    format="png"
    (imageCropped)="imageCropped($event)"
  >
  </image-cropper>

  <ng-template pTemplate="footer">
    <app-button
      [label]="'section-action.cancel' | translate | sentenceCase"
      variant="outline"
      (onClick)="isCropperModal = false"
    ></app-button>

    <app-button
      [label]="'section-action.confirm' | translate | sentenceCase"
      (onClick)="onConfirmCropper()"
    ></app-button>
  </ng-template>
</p-dialog>
