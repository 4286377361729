import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[frozenColStyle]',
})
export class FrozenColStyleDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}
  @Input({ required: true }) frozenColStyle: 'left' | 'right' = 'left';

  ngOnInit(): void {
    // Apply the specified styling to the host element
    const pseudoElement = this.renderer.createElement('div');
    this.renderer.setStyle(pseudoElement, 'content', '""');
    this.renderer.setStyle(pseudoElement, 'position', 'absolute');
    this.renderer.setStyle(pseudoElement, 'height', '100%');
    this.renderer.setStyle(pseudoElement, 'width', '16px');
    this.renderer.setStyle(
      pseudoElement,
      this.frozenColStyle === 'left' ? 'right' : 'left',
       '-16px',
    );
    this.renderer.setStyle(pseudoElement, 'top', '0');
    this.renderer.setStyle(
      pseudoElement,
      'box-shadow',
      `inset ${
        this.frozenColStyle === 'left' ? '10px' : '-10px'
      } 0 8px -8px rgba(5, 5, 5, 0.06)`,
    );

    // Append the pseudo-element to the host element
    this.renderer.appendChild(this.el.nativeElement, pseudoElement);
  }
}
