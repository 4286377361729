import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FileControllerService } from '@soctrip/angular-storage-service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-upload-img',
  templateUrl: './custom-upload-img.component.html',
  styleUrls: ['./custom-upload-img.component.scss'],
})
export class CustomUploadImgComponent implements OnInit {
  @Output() imageData = new EventEmitter();
  @Input() svgType: boolean = false;
  @Input() imgThumnail: string = '';
  @Input() isEditMode: boolean = false;
  @Input() styleClass: string;
  @Input() isStyle: boolean = false;

  @ViewChild('fileInput') fileInput!: ElementRef;
  SoctripIcons = SoctripIcons;
  promotionProgram = 'promotion-program.';

  files: any;
  uploadedImage: any;
  imageName: string | null = null;
  imageSize: number | null = null;
  isLoading: boolean = false;
  apiCallCount = 0;
  fileSubscription: Subscription;

  isChangeImage: boolean = false;
  imageUrl: string | undefined;

  constructor(
    private messageService: MessageService,
    public file: FileService,
    private filesService: FileControllerService,
  ) {}

  ngOnInit(): void {
    if (!this.isEditMode && this.imgThumnail) this.getFile(this.imgThumnail);
  }

  getAvatarDefault() {
    return 'assets/imgs/default/avatar.webp';
  }

  private handleFile(file: File): void {
    this.imageName = file.name;
    this.imageSize = file.size;
    if (this.isImage(file) && this.isFileSizeValid(file, 5 * 1024 * 1024)) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.uploadedImage = e.target.result;
        this.imageData.emit({ id: this.uploadedImage, file: file });
      };
      reader.readAsDataURL(file);
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Invalid file or file size exceeds 5MB',
      });
    }
  }

  onFileSelected(event: any): void {
    this.imgThumnail = '';
    const file = event?.target?.files[0];
    if (!file) {
      this.handleFile(event);
    } else if (
      file &&
      this.isImage(file) &&
      this.isFileSizeValid(file, 5 * 1024 * 1024)
    ) {
      this.isLoading = true;
      this.imageName = file.name;
      this.imageSize = file.size;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.uploadedImage = e.target.result;
          this.imageData.emit({ id: this.uploadedImage, file: file });
          this.isLoading = false;
        };
        reader.readAsDataURL(file);
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select a valid PNG or JPG file smaller than 5MB',
      });
      this.resetUpload();
    }
  }

  replaceImage(): void {
    this.fileInput.nativeElement.click();
  }

  removeImage(): void {
    this.resetUpload();
  }

  private resetUpload(): void {
    this.uploadedImage = null;
    this.imageName = null;
    this.imageSize = null;
    this.fileInput.nativeElement.value = '';
    this.imgThumnail = '';
    this.isLoading = false;
    this.imageData.emit('');
  }

  private isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files: File | null = this.getFileFromEvent(event);
    if (files) {
      this.onFileSelected(files);
    }
  }

  private getFileFromEvent(event: DragEvent): File | null {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer && dataTransfer.items) {
      for (let i = 0; i < dataTransfer.items.length; i++) {
        if (dataTransfer.items[i].kind === 'file') {
          return dataTransfer.items[i].getAsFile();
        }
      }
    } else {
      if (dataTransfer && dataTransfer.files.length > 0) {
        return dataTransfer.files[0];
      }
    }
    return null;
  }
  private isFileSizeValid(file: File, maxSize: number): boolean {
    return file.size <= maxSize;
  }

  getThumbnailById(id: string) {
    return this.file.getThumbnailWebp(id);
  }
  isFetchedImg: boolean;
  getFile(id: string) {
    this.isFetchedImg = true;

    this.filesService.getFile(id).subscribe({
      next: (res) => {
        this.imageName = res.data?.original_name;
        this.imageSize = res.data?.size;
      },
      error: (e) => {
        this.isFetchedImg = false;
        console.log('Error:', e);
      },
      complete: () => {
        this.isFetchedImg = false;
      },
    });
  }
}
