import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import {
  CalendarOptionsChoose,
  CalendarOptionsSelect,
  ICalendarOption,
} from 'src/app/core/constants/dashboard.config';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';

export interface CalendarFilterType {
  frame: 'MONTHS' | 'DAYS' | 'HOURS';
  rangeDates: Date[];
}

@Component({
  selector: 'app-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
})
export class CalendarFilterComponent {
  protected readonly SoctripIcons = SoctripIcons;
  protected readonly optionsChoose = CalendarOptionsChoose;
  protected readonly optionsSelect = CalendarOptionsSelect;
  protected readonly dateNow = new Date();

  @Input({ required: true }) filter: CalendarFilterType;
  @Input() option: string;
  @Input() isKeepState = false;
  @Input() isHadAllTime = false;

  @Output() optionChange = new EventEmitter<string>();
  @Output() filterChange = new EventEmitter<CalendarFilterType>();

  date: Date[] | Date;
  dateTitle: string;
  optionSelected: string;

  constructor() {}

  ngOnInit() {
    const startDate = this.filter.rangeDates[0];
    const endDate = this.filter.rangeDates[1];

    if (this.isKeepState) {
      if (this.option) {
        this.optionSelected = this.option;
      }

      if (startDate && endDate) {
        this.dateTitle = `${moment(startDate).format('DD/MM/yyyy')} - ${moment(
          endDate,
        ).format('DD/MM/yyyy')}`;

        if (
          ['today', 'yesterday', 'byDay', 'byMonth', 'byYear'].includes(
            this.optionSelected,
          )
        ) {
          this.date = new Date(startDate);
        } else {
          this.date = [new Date(startDate), new Date(endDate)];
        }
      } else {
        this.initFilter();
      }
    } else {
      this.initFilter();
    }
  }

  initFilter() {
    if (this.isHadAllTime) {
      this.date = [];
      this.optionSelected = 'all-time';
    } else {
      this.date = new Date();
      this.dateTitle = moment(this.date).format('DD/MM/yyyy');
      this.optionSelected = 'byDay';
    }
  }

  onSelectDate(evt: Date) {
    switch (this.optionSelected) {
      case 'byDay': {
        this.date = evt;
        this.dateTitle = moment(evt).format('DD/MM/yyyy');
        this.onChangeFilter();

        this.option = 'byDay';
        this.optionChange.emit(this.option);
        break;
      }
      case 'byWeek': {
        const start = moment(evt).startOf('week').add(1, 'day');
        const end = moment(evt).endOf('week').add(1, 'day');

        this.date = [start.toDate(), end.toDate()];
        this.dateTitle = `${moment(start).format('DD/MM/yyyy')} - ${moment(
          end,
        ).format('DD/MM/yyyy')}`;
        this.onChangeFilter();

        this.option = 'byWeek';
        this.optionChange.emit(this.option);
        break;
      }
      case 'byMonth': {
        this.date = evt;
        this.dateTitle = moment(this.date).format('MM/yyyy');

        const startOfMonth = moment(evt).startOf('month').toDate();
        const endOfMonth = moment(evt).endOf('month').toDate();

        this.filter = {
          frame: 'DAYS',
          rangeDates: [startOfMonth, endOfMonth],
        };
        this.filterChange.emit(this.filter);

        this.option = 'byMonth';
        this.optionChange.emit(this.option);
        break;
      }
      case 'byYear': {
        this.date = evt;
        this.dateTitle = moment(this.date).format('yyyy');

        const startOfYear = moment(evt).startOf('year').toDate();
        const endOfYear = moment(evt).endOf('year').toDate();

        this.filter = {
          frame: 'MONTHS',
          rangeDates: [startOfYear, endOfYear],
        };
        this.filterChange.emit(this.filter);

        this.option = 'byYear';
        this.optionChange.emit(this.option);
        break;
      }
    }
  }

  onSelectOption(option: ICalendarOption) {
    this.optionSelected = option.value;
    switch (option.value) {
      case 'today': {
        this.date = new Date();
        this.dateTitle = moment(this.date).format('DD/MM/yyyy');
        this.onChangeFilter();

        this.option = 'today';
        this.optionChange.emit(this.option);
        break;
      }
      case 'yesterday': {
        const yesterday = moment().subtract(1, 'day');
        this.date = yesterday.toDate();
        this.dateTitle = yesterday.format('DD/MM/yyyy');
        this.onChangeFilter();

        this.option = 'yesterday';
        this.optionChange.emit(this.option);
        break;
      }
      case 'pastWeek': {
        const startDate = moment().subtract(7, 'day').startOf('day');
        const endDate = moment().subtract(1, 'day').endOf('day');

        this.date = [startDate.toDate(), endDate.toDate()];
        this.dateTitle = `${startDate.format('DD/MM/yyyy')} - ${endDate.format(
          'DD/MM/yyyy',
        )}`;
        this.onChangeFilter();

        this.option = 'passWeek';
        this.optionChange.emit(this.option);
        break;
      }
      case 'pastMonth': {
        const startDate = moment().subtract(30, 'day').startOf('day');
        const endDate = moment().subtract(1, 'day').endOf('day');

        this.date = [startDate.toDate(), endDate.toDate()];
        this.dateTitle = `${startDate.format('DD/MM/yyyy')} - ${endDate.format(
          'DD/MM/yyyy',
        )}`;
        this.onChangeFilter();

        this.option = 'pastMonth';
        this.optionChange.emit(this.option);
        break;
      }
      case 'byDay': {
        this.date = new Date();
        break;
      }
      case 'byWeek': {
        this.date = [];
        break;
      }
      case 'byMonth': {
        this.date = new Date();
        break;
      }
      case 'byYear': {
        this.date = new Date();
        break;
      }
    }
  }

  get mode() {
    if (
      ['today', 'yesterday', 'byDay', 'byMonth', 'byYear'].includes(
        this.optionSelected,
      )
    ) {
      return 'single';
    } else {
      return 'range';
    }
  }

  get view() {
    if (this.optionSelected === 'byMonth') {
      return 'month';
    } else if (this.optionSelected === 'byYear') {
      return 'year';
    } else {
      return 'date';
    }
  }

  get disabled() {
    if (
      ['byDay', 'byWeek', 'byMonth', 'byYear'].includes(this.optionSelected)
    ) {
      return false;
    } else {
      return true;
    }
  }

  onChangeFilter() {
    const type = Array.isArray(this.date) ? 'range' : 'single';
    if (type === 'single') {
      const date = this.date as Date;

      this.filter = {
        frame: 'HOURS',
        rangeDates: [
          moment(date).startOf('day').toDate(),
          moment(date).endOf('day').toDate(),
        ],
      };
    } else if (type === 'range') {
      const range = this.date as Date[];
      this.filter = {
        frame: 'DAYS',
        rangeDates: [
          moment(range[0]).startOf('day').toDate(),
          moment(range[1]).endOf('day').toDate(),
        ],
      };
    }
    this.filterChange.emit(this.filter);
  }

  onSelectAllTime() {
    this.date = [];
    this.dateTitle = '';
    this.optionSelected = 'all-time';

    this.filter = {
      frame: 'DAYS',
      rangeDates: [],
    };
    this.filterChange.emit(this.filter);

    this.option = 'all-time';
    this.optionChange.emit(this.option);
  }
}
