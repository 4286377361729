import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { MessageService } from 'primeng/api';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { IOrderProduct } from 'src/app/core/models/interfaces/shop';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { ShipmentControllerService } from '@soctrip/angular-order-service';
import { Carrier } from 'src/app/core/models/interfaces/shipment';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { Router } from '@angular/router';
import { ShipmentBody } from 'src/app/core/models/interfaces/shipment';

@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.scss'],
})
export class OrderInformationComponent implements OnChanges {
  @Input() order: OrderDetailDTO;
  @Input() estimatedArrivalDelivery: string = '';
  @Input() trackingTypeList: Carrier[] = [];
  @Input() isReshipMode: boolean = false;
  @Input() isClearTrackingMethod: boolean = false;

  @Output() onUpdateOrderInformation = new EventEmitter<any[]>();

  readonly SoctripIcons = SoctripIcons;
  isEditMode = false;
  orderPrefix = 'section-order-management.';
  noteData: string = '';
  tracking?: Carrier;
  preTracking?: Carrier;
  preNote: string = '';
  actionPrefix = 'section-action.';
  isLoading: boolean = false;

  constructor(
    private messageService: MessageService,
    private translator: CustomTranslateService,
    private shipmentControllerService: ShipmentControllerService,
    private shipmentService: ShipmentService,
    private router: Router,
  ) {}

  get getAllProducts(): IOrderProduct[] {
    if (this.order?.shops) {
      const allProducts = this.order?.shops?.flatMap(
        (shop) => shop?.products || [],
      ) as IOrderProduct[];
      return allProducts;
    }
    return [];
  }

  get getTickets() {
    const tickets = this.getAllProducts?.flatMap(
      (product) => product?.ticketQrCodes || [],
    );
    return tickets;
  }

  get isTicket(): boolean {
    if (this.order?.shops) {
      const allProducts = this.order.shops.flatMap(
        (shop) => shop?.products || [],
      );
      return allProducts.some((product) => product?.is_ticket);
    }
    return false;
  }

  ngOnChanges() {
    this.noteData = this.order?.note || '';
    const trackingType = this.order.carrier || '';
    if (!this.isClearTrackingMethod) {
      this.tracking = this.trackingTypeList.find(
        (trackingElement) => trackingType === trackingElement.carrier_name,
      );
    } else {
      this.tracking = undefined;
    }
  }

  onReShipOrder(id: string) {
    this.isLoading = true;
    const body: ShipmentBody = {
      rate: this.tracking?.id ?? '',
      note: this.noteData ?? '',
    };
    this.shipmentService.shipmentsReShipId(id, body).subscribe({
      next: (res: any) => {
        if (res?.data) {
          this.messageService.add({
            severity: 'success',
            summary: this.translator.sentenceCase(`common.success`),
            detail: this.translator.sentenceCase(
              `${this.orderPrefix}recreate-shipping-order-successfully`,
            ),
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: this.translator.sentenceCase(`common.warning`),
            detail: `${res?.error?.message}`,
          });
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          detail: this.translator.sentenceCase(`common.an-error-occurred`),
        });
      },
      complete: () => {
        this.isLoading = false;
        this.router.navigate(['order']);
      },
    });
  }

  onCopySuccess(event: any) {
    this.messageService.add({
      severity: 'success',
      detail: this.translator.sentenceCase(
        `${this.orderPrefix}copied-successfully`,
      ),
    });
  }

  onClickShipmentTrackUrl(url?: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  onEditOrderInformation() {
    if (!this.isEditMode) {
      this.preTracking = this.tracking;
      this.preNote = this.noteData;
    } else {
      this.tracking = this.preTracking;
      this.noteData = this.preNote;
    }
    this.isEditMode = !this.isEditMode;
  }

  convertTimestampToDateTime(timestamp: string): string {
    if (timestamp && timestamp !== '0') {
      const date = new Date(Number(timestamp));

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    } else {
      return '--';
    }
  }
}
