<div
  class="flex flex-col gap-5 p-6 border border-gray-200 rounded-lg bg-white w-full"
>
  <div class="flex justify-between">
    <span class="text-lg font-semibold text-gray-900">
      {{ orderPrefix + "order-information" | translate | sentenceCase }}</span
    >

    <div *ngIf="isReshipMode" class="flex gap-3">
      <button
        class="h-9 text-blue-600 font-semibold w-fit"
        (click)="onEditOrderInformation()"
        *ngIf="!isTicket"
      >
        {{
          isEditMode
            ? ("section-action.cancel" | translate | sentenceCase)
            : ("section-action.edit" | translate | sentenceCase)
        }}
      </button>
      <ng-container *ngIf="isEditMode; else applyInformation">
        <app-button
          *ngIf="!isTicket"
          class="!h-9 bg-blue-600 rounded-lg text-white font-semibold w-fit"
          [loading]="isLoading"
          (onClick)="onReShipOrder(order.id ?? '')"
          styleClass="border-orange-dark-600 text-orange-dark-600"
          [label]="orderPrefix + 'create-new-order' | translate"
        ></app-button>
      </ng-container>
      <ng-template #applyInformation>
        <app-button
          *ngIf="!isTicket"
          class="!h-9 bg-blue-600 rounded-lg text-white font-semibold w-fit"
          [loading]="isLoading"
          (onClick)="onReShipOrder(order.id ?? '')"
          styleClass="border-orange-dark-600 text-orange-dark-600"
          [label]="orderPrefix + 'recreate-order' | translate"
        ></app-button>
      </ng-template>
    </div>
  </div>

  <div class="grid grid-cols-3 gap-4">
    <!-- ORDER ID -->
    <div class="flex flex-col gap-1">
      <span class="text-sm font-medium text-gray-500">{{
        orderPrefix + "order-id" | translate | sentenceCase
      }}</span>
      <div class="flex flex-row gap-1">
        <span class="text-sm font-medium text-gray-700 truncate">
          {{ order?.shops?.[0]?.code }}</span
        >
        <i
          class="{{
            SoctripIcons.COPY_03
          }} text-xl text-blue-600 cursor-pointer"
          ngxClipboard
          [cbContent]="order?.shops?.[0]?.code ?? ''"
          (cbOnSuccess)="onCopySuccess(order?.shops?.[0]?.code)"
        ></i>
      </div>
    </div>

    <!-- STATUS -->
    <div class="flex flex-col gap-1">
      <span class="text-sm font-medium text-gray-500">{{
        "common.status" | translate | sentenceCase
      }}</span>
      <div class="flex items-center">
        <span
          class="order-status text-xs font-medium {{
            order?.status?.status_property?.tab
          }}"
          [style.background-color]="
            order?.status?.status_property?.color || '#EAECF0'
          "
          [style.color]="
            order?.status?.status_property?.font_color || '#344054'
          "
        >
          {{ order?.status?.name }}
        </span>
      </div>
    </div>

    <!-- ORDER DATE -->
    <div class="flex flex-col gap-1">
      <span class="text-sm font-medium text-gray-500">{{
        orderPrefix + "order-date" | translate | sentenceCase
      }}</span>
      <div class="flex flex-row gap-1">
        <span class="text-sm font-medium text-gray-700 truncate">{{
          order?.payment?.created_at || 0 | dateFormat: true : true
        }}</span>
      </div>
    </div>

    <!-- SHIPMENT CODE -->
    <div *ngIf="!isTicket" class="flex flex-col gap-1">
      <div class="text-gray-500 font-medium text-sm">
        {{ orderPrefix + "shipment-code" | translate | sentenceCase }}
      </div>
      <div class="flex items-center gap-2 truncate text-sm">
        <span class="truncate">
          {{ order?.shipment_code || "--" }}
        </span>

        <i
          *ngIf="order?.shipment_code"
          class="{{
            SoctripIcons.COPY_03
          }} text-xl text-blue-600 cursor-pointer"
          ngxClipboard
          [cbContent]="order?.shipment_code"
          (cbOnSuccess)="onCopySuccess(order?.shipment_code)"
        ></i>
      </div>
    </div>

    <!-- ESTIMATED DELIVERY -->
    <div *ngIf="!isTicket" class="flex flex-col gap-1">
      <div class="text-gray-500 font-medium text-sm">
        {{
          orderPrefix + "estimated-arrival-delivery" | translate | sentenceCase
        }}
      </div>
      <div class="flex items-center gap-2 truncate text-sm">
        <span class="truncate">
          {{ convertTimestampToDateTime(this.estimatedArrivalDelivery) }}
        </span>
      </div>
    </div>

    <!-- TRACKING -->
    <div *ngIf="!isTicket" class="flex flex-col gap-1">
      <a
        (click)="onClickShipmentTrackUrl(order?.shipment_track_url)"
        class="w-full flex gap-1"
        [ngClass]="
          order?.shipment_track_url
            ? 'cursor-pointer'
            : 'opacity-70 cursor-no-drop'
        "
      >
        <img src="/assets/imgs/Icon_Tracking.svg" alt="tracking" />
        <div class="text-gray-500 font-medium text-sm">
          {{ orderPrefix + "shipment-tracking" | translate | sentenceCase }}
        </div>
      </a>
      <ng-container *ngIf="!isEditMode; else editTracking">
        <div class="flex items-center gap-2 truncate text-sm">
          <span class="truncate">
            {{ tracking?.carrier_name }}
          </span>
        </div>
      </ng-container>
      <ng-template #editTracking>
        <p-dropdown
          class="p-dropdown-custom"
          optionLabel="carrier_name"
          [options]="trackingTypeList"
          [(ngModel)]="tracking"
          placeholder="Select a tracking type"
          styleClass="w-full"
        />
      </ng-template>
    </div>
  </div>

  <!-- AliasTickets -->
  <div *ngIf="isTicket" class="grid gap-1">
    <div class="text-gray-500 font-medium text-sm">
      {{ "common.e-ticket" | translate | sentenceCase }}
    </div>
    <div class="flex items-center flex-row flex-wrap gap-2">
      <div *ngFor="let ticket of getTickets; let i = index; let last = last">
        <span>{{ ticket?.order || "--" }}<span *ngIf="!last">, </span></span>
      </div>
    </div>
  </div>

  <!-- NOTE -->
  <div class="flex flex-col gap-1">
    <span class="text-sm font-medium text-gray-500">
      {{ "common.note" | translate | sentenceCase }}
    </span>
    <ng-container *ngIf="!isEditMode; else editNote">
      <span class="text-sm font-medium text-gray-700">{{ noteData }}</span>
    </ng-container>
    <ng-template #editNote>
      <div class="card flex justify-content-center">
        <input
          type="text"
          styleClass="w-full"
          pInputText
          [(ngModel)]="noteData"
        />
      </div>
    </ng-template>
  </div>
</div>
