<div class="w-full" [ngClass]="styleClass">
  <button
    [disabled]="disabled"
    (click)="visible = !visible"
    class="relative w-full"
  >
    <input
      class="w-full h-11"
      type="text"
      pInputText
      readonly
      [value]="dateString"
      placeholder="dd/mm/yyyy hh:mm:ss"
      [disabled]="disabled"
    />
    <i
      class="absolute right-4 top-1/2 translate-y-[-50%] ng-dirty ng-invalid"
      [class]="SoctripIcons.CALENDAR"
      [ngClass]="disabled ? 'opacity-50' : 'cursor-pointer'"
    >
    </i>
  </button>
  <p-overlay [(visible)]="visible">
    <div class="w-[324px] bg-white border rounded-lg mt-2 pb-4">
      <p-calendar
        styleClass="w-full select-time"
        [inline]="true"
        [(ngModel)]="date"
        (ngModelChange)="onChangeDate()"
        (onSelect)="visible = !visible"
        [minDate]="minDate"
        dateFormat="dd/mm/yy hh:mm:ss"
      ></p-calendar>

      <div class="w-full flex gap-2 items-center px-5 border-t pt-4 mt-1">
        <div class="flex-1">
          <input
            class="w-full h-10"
            type="text"
            pInputText
            [min]="0"
            [max]="23"
            [maxLength]="2"
            [(ngModel)]="hours"
            (keypress)="keyPress($event)"
            (ngModelChange)="onChangeTime('hours')"
          />
        </div>
        <span>:</span>
        <div class="flex-1">
          <input
            #minuteInput
            class="w-full h-10"
            type="text"
            pInputText
            [min]="0"
            [max]="59"
            [maxLength]="2"
            [(ngModel)]="minutes"
            (keypress)="keyPress($event)"
            (ngModelChange)="onChangeTime('minutes')"
          />
        </div>
        <span>:</span>
        <div class="flex-1">
          <input
            #secondInput
            class="w-full h-10"
            type="text"
            pInputText
            [min]="0"
            [max]="59"
            [maxLength]="2"
            [(ngModel)]="seconds"
            (keypress)="keyPress($event)"
            (ngModelChange)="onChangeTime('seconds')"
          />
        </div>
      </div>
    </div>
  </p-overlay>
</div>
